<template>
  <div>
    <nav-header />
    <div class="container">
      <el-row type="flex">
        <el-col :span="4">
          <sider-bar />
        </el-col>
        <el-col :span="20">
          <div class="title">{{ $route.meta.title }}</div>
          <div class="content">
            <router-view />
          </div>
        </el-col>
      </el-row>
    </div>
    <nav-footer />
  </div>
</template>
<script>
import SiderBar from "@/pages/AccountCenter/SiderBar";
import NavHeader from "@/components/NavHeader";
import {Row, Col} from "element-ui"
import NavFooter from "@/components/NavFooter";
export default {
  components: {
    NavFooter,
    NavHeader,
    SiderBar,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 1226px;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #333;
  width: 100%;
  height: 40px;
  background-color: #eee;
}

.content {
  padding: 16px;
}
</style>
