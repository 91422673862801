<template >
  <div style="height: 100%">
    <div class="title">
      账户中心
    </div>
    <el-menu
      :default-active="$route.name"
      class="el-menu-vertical-demo"
      @select="handleSelect"
      style="height: calc(100% - 40px)" v-if="getUserInfo.role=='superAdmin'"
    >
      <el-menu-item
        v-for="item in menuList"
        :key="item.name"
        :index="item.name"
      >
        
        <el-popover
          placement="right"
          trigger="hover"
          v-if="item.children && !item.meta.hideChildren"
        >
          <el-menu-item
            v-for="i in item.children"
            :key="i.name"
            :index="i.name"
          >
            <span slot="title">
              {{ i.meta.title }}
            </span>
          </el-menu-item>
          <span slot="reference">
            <span slot="title">
            {{ item.meta.title }}
            <i
              class="el-icon-arrow-right icon"
            />
            </span>
          </span>
        </el-popover>
        <span v-else slot="title">
          {{ item.meta.title }}
        </span>
      </el-menu-item>
    </el-menu>



    <el-menu
        :default-active="$route.name"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        style="height: calc(100% - 40px)" v-if="getUserInfo.role=='user'||getUserInfo.role=='Admin'"
    >
      <el-menu-item
          v-for="item in filteredMenuList"
          :key="item.name"
          :index="item.name"
      >
        <span slot="title">
          {{ item.meta.title }}
        </span>
      </el-menu-item>
    </el-menu>


  </div>
</template>



<script>
import {Menu, MenuItem, MenuItemGroup, Popover, Submenu} from "element-ui"
import {mapGetters} from "vuex";

export default {
  components: {
    [Menu.name]: Menu,
    [Submenu.name]: Submenu,
    [MenuItem.name]: MenuItem,
    [MenuItemGroup.name]: MenuItemGroup,
    [Popover.name]: Popover,
  },
  data() {
    return {
      menuList: [],
      firstMenuList: [],
      secondMenuList: [],
      thirdMenuList: [],
    }
  },
  mounted() {
    this.getMenuList()
  },
  methods: {
    handleSelect(key) {
      this.$router.push({
        name: key
      })
    },
    getMenuList() {
      const routes = this.$router.options.routes
      for (const route of routes) {
        if (route.name === "account") {
          this.menuList = route.children
          break
        }
      }
    }
  },
  computed: {
    ...mapGetters("user", ["getUserInfo"]),
    filteredMenuList() {
      return this.menuList.filter(item => item.meta.title === "信息维护");
    }
  },

}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #333;
  width: 100%;
  height: 40px;
  background-color: #eee;
}

.icon {
  width: 14px;
  height: 14px;
  margin-left: 90px;
  margin-bottom: 7px;
}
</style>
